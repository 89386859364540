.customPhoneInput {
    display: inline-flex;
    flex: 1 !important;
    background-color: var(--green-background) !important;
    color: var(--light-font-color);
    border: 0.0625rem solid var(--border-default-color) !important;
    border-radius: 5px !important;
    width: 100% !important;
    padding: 1.3rem 5rem !important;
}

.customPhoneInput input {
    /* padding: 1.75rem 1rem !important; */
    flex: 1;
    width: 100% !important;
    outline: none !important;
}

.customPhoneInput .react-select__control {
    background-color: var(--green-background) !important;
    /* Change to the desired background color */
    border: none !important;
}

/* Style the flag icon and country name in the dropdown */
.customPhoneInput .react-select__option {
    color: var(--your-color-here);
    /* Change to the desired text color */
    background-color: var(--green-background) !important;
    /* Change to the desired background color */
}

/* Style the selected country */
.customPhoneInput .react-select__single-value {
    color: var(--light-font-color) !important;
    /* Change to the desired text color */
}