.App {
    /* App.css */
    margin: 0 auto;
    /* Center the container */
    max-width: 1800px;
    /* Set maximum width for the entire app */
}

.carousel {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 19.75em;
}

@media only screen and (max-width: 920px) {
    .carousel {
        height: 10.8109375em;
    }
}