/* button component styling */
.button {
    width: 100%;
    display: flex;
    padding: 0.5rem 0.75rem;
    justify-content: center;
    align-items: center;
    gap: 0.75rem;
    cursor: pointer;
    border-radius: 0.5rem;
    background-color: var(--primary-color);
}

.button button {
    border: none;
    display: flex;
    align-items: center;
    background-color: transparent;
    color: var(--light-font-color);
    font-family: var(--font-family);
    font-size: var(--small-text-size);
    font-style: normal;
    font-weight: var(--medium-font-weight);
    line-height: normal;
    cursor: pointer;
    gap: 0.75rem;
}

/* inpput component */
.input {
    width: 100%;
    gap: 0.5rem;
    display: flex;
    flex-direction: column;
}

.input input {
    width: 100%;
    display: flex;
    font-size: var(--small-text-size);
    padding: 0.75rem 1rem;
    align-items: center;
    background-color: var(--background-color);
    gap: 0.5rem;
    border-radius: 0.25rem;
    font-weight: var(--light-font-weight);
    color: var(--dark-font-color);
    border: 0.0625rem solid var(--grey-font-color);
}

.input div {
    display: flex;
    align-items: center;
    border-radius: 0.25rem;
    border: 0.0625rem solid var(--grey-font-color);
}

.input div svg {
    margin-right: 12px;
}

.input div input {
    border: none;
}

.text_area {
    width: 100%;
    gap: 0.5rem;
    display: flex;
    flex-direction: column;
}

.text_area textarea {
    width: 100%;
    height: 8.4375em;
    resize: none;
    display: flex;
    font-size: var(--small-text-size);
    padding: 0.75rem 1rem;
    align-items: center;
    background-color: var(--background-color);
    gap: 0.5rem;
    border-radius: 0.25rem;
    font-weight: var(--light-font-weight);
    color: var(--dark-font-color);
    border: 0.0625rem solid var(--grey-font-color);
}

.text_area textarea:focus {
    outline: none;
}

.input input:focus {
    outline: none;
}

/* card component styling */

.card {
    display: flex;
    padding: 1.875rem 1.25rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.875rem;
    border-radius: 0.9375rem;
    box-shadow: var(--box-shadow);
}