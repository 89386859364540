.footer {
    display: flex;
    width: 100%;
    padding: 3.125rem 6.25rem;
    flex-direction: row;
    justify-content: space-between;
    gap: 2.875rem;
    font-family: var(--font-family);
    border-top: 1px solid var(--primary-40, #588B90);
    background-color: var(--green-background);
}

.footer div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.875rem;
}

.footer p {
    color: var(--button-text-color);
    font-size: var(--small-text-size);
    font-style: normal;
    font-weight: var(--light-font-weight);
    line-height: normal;
}

.footer a {
    text-decoration: none;
    color: var(--button-text-color);
    font-size: var(--small-text-size);
    font-style: normal;
    font-weight: var(--light-font-weight);
    line-height: normal;
}

.footer div h1 {
    color: var(--button-text-color);
    font-size: 1.5rem;
    font-style: normal;
    font-weight: var(--medium-font-weight);
    line-height: normal;
}

.footer .footer_company_info {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    width: 30%;
}

.contact .footer_socials {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

@media only screen and (max-width: 920px) {
    .footer {
        flex-direction: column;
        padding: 3.125rem 1.5625rem;
    }

    .company svg {
        width: 96px;
        height: 47.461px;
    }

    .footer p {
        font-size: 0.75rem;
    }

    .footer a {
        font-size: 0.75rem;
    }

    .footer div h1 {
        font-size: 1rem;
    }

    .footer .footer_company_info {
        width: 100%;
    }
}